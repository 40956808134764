<template>
    <div class="multipleOrderOneCar" v-loading="loading">
        <div class="car-info">
            <div class="top-wrapper">
                <span class="title">车辆信息</span>
            </div>
            <div class="info-item">
                <label class="name">车号：</label>
                <el-input
                    v-model="selectCar.plate_small_number"
                    readonly
                    suffix-icon="el-icon-arrow-down"
                    @focus="selectCarNo"
                    placeholder="请选择车号"
                ></el-input>
            </div>
            <div class="info-item">
                <label class="name">车牌号：</label>
                <el-input v-model="selectCar.plate_number" readonly></el-input>
            </div>
            <div class="info-item">
                <label class="name">司机电话：</label>
                <el-input v-model="selectCar.driver_phone" readonly></el-input>
            </div>
            <div class="info-item">
                <label class="name">司机姓名：</label>
                <el-input v-model="selectCar.driver_name" readonly></el-input>
            </div>
            <div class="info-item">
                <label class="name">产品类型：</label>
                <el-radio-group v-model="form.product_type" :disabled="list.length>0">
                    <el-radio :label="1">
                        混凝土
                    </el-radio>
                    <el-radio :label="2">
                        润泵砂浆
                    </el-radio>
                </el-radio-group>
            </div>
            <div class="info-item">
                <label class="name">生产机组：</label>
                <el-select
                    v-model="form.line_code"
                    placeholder="选择生产机组"
                    @change="lineChange"
                >
                    <el-option
                        v-for="(item,index) in lineList"
                        :key="index"
                        :label="item.line_name"
                        :value="item.line_code"
                    ></el-option>
                </el-select>
            </div>
            <div class="info-item">
                <label class="name">罐车方量：</label>
                <el-input v-model="form.bulk_density" readonly></el-input>
            </div>
            <div class="info-item">
                <label class="name">派单总方量：</label>
                <el-input v-model="total_quantity" readonly></el-input>
            </div>
        </div>
        <div class="task-order-list">
            <div class="top-wrapper">
                <span class="title">添加任务单</span>
            </div>
            <div class="table-box">
                <div class="table-head">
                    <table>
                        <tr>
                            <th style="width: 1.12rem;">
                                序号
                            </th>
                            <th style="width: 2.3rem;">
                                任务编号
                            </th>
                            <th style="width: 2rem;">
                                客户名称
                            </th>
                            <th style="width: 2rem;">
                                工程名称
                            </th>
                            <th style="width: 1.4rem;">
                                施工部位
                            </th>
                            <th style="width: 1.58rem;">
                                产品标号
                            </th>
                            <th style="width: 1.58rem;">
                                方量(方)
                            </th>
                            <th style="width: 1.5rem;">
                                调整顺序
                            </th>
                            <th>
                                操作
                            </th>
                        </tr>
                    </table>
                </div>
                <div class="table-body table-bg">
                    <table v-if="list.length>0">
                        <tbody>
                            <draggable v-model="list" animation="500" force-fallback="true">
                                <tr v-for="(item,index) in list" :key="index">
                                    <td style="width:1.12rem">
                                        {{ index+1 }}
                                    </td>
                                    <td style="width: 2.3rem;" @click="selectOrder('radio',index)">
                                        <el-input
                                            title="选择任务单"
                                            v-model="item.task_number"
                                            suffix-icon="iconfont iconzhankai2"
                                            readonly
                                        ></el-input>
                                    </td>
                                    <td style="width: 2rem;" :title="item.customer_name">
                                        <div class="cell">
                                            {{ item.customer_name }}
                                        </div>
                                    </td>
                                    <td style="width: 2rem;" :title="item.project_name">
                                        <div class="cell">
                                            {{ item.project_name }}
                                        </div>
                                    </td>
                                    <td style="width: 1.4rem;" :title="item.place_detail">
                                        <div class="cell">
                                            {{ item.place_detail }}
                                        </div>
                                    </td>
                                    <td style="width: 1.58rem;">
                                        <div class="cell">
                                            {{ item.strength_grade_name+' '+item.special_require_name }}
                                        </div>
                                    </td>
                                    <td style="width: 1.58rem;">
                                        <el-input
                                            v-if="form.product_type===1"
                                            type="number"
                                            v-model="item.concrete_volume"
                                            @input="changeVolume"
                                            placeholder="请输入方量"
                                        ></el-input>
                                        <el-input
                                            v-else
                                            type="number"
                                            v-model="item.mortar_volume"
                                            @input="changeVolume"
                                            placeholder="请输入方量"
                                        ></el-input>
                                    </td>
                                    <td style="width: 1.5rem;">
                                        <div class="cell">
                                            <div :class="{'sort-btn':true,disable:index==0}" @click="upMove(index)">
                                                <i class="el-icon-top"></i>
                                            </div>
                                            <div
                                                :class="{'sort-btn':true,disable:index==list.length-1}"
                                                @click="downMove(index)"
                                            >
                                                <i class="el-icon-bottom" style="font-size: 0.14rem"></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="flex: 1">
                                        <div class="cell">
                                            <el-button
                                                class="delbtn"
                                                type="danger"
                                                plain
                                                size="mini"
                                                @click="removeRow"
                                            >
                                                删除
                                            </el-button>
                                        </div>
                                    </td>
                                </tr>
                            </draggable>
                        </tbody>
                    </table>
                    <div v-else class="el-table__empty-block" style="width: 100%; height: 100%;">
                        <span class="el-table__empty-text">暂无数据</span>
                    </div>
                </div>
                <div class="add-btn" @click="selectOrder('checkbox')">
                    <i class="iconfont icontianjia1"></i><span>点击此处新增</span>
                </div>
                <div class="tips">
                    <i class="iconfont icontishi"></i>
                    <span>注：系统将按照任务单序号的顺序进行派单和生产，如需修改顺序请点击某行空白处拖动调整或右侧箭头图标调整。</span>
                </div>
            </div>
        </div>
        <div class="btns-wrapper">
            <el-button class="cancel-btn" @click="cancel">
                取消
            </el-button>
            <el-button class="sure-btn" @click="saveData">
                确认
            </el-button>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: 'multiple-order-one-car',
    components: { draggable },
    data() {
        return {
            loading: false,
            // 列表
            list: [],
            // 查询过滤参数
            filterParams: {
                station: '',
                start_time: '',
                end_time: '',
                search: '',
            },
            // 选择的车辆
            selectCar: {},
            // 车号
            shch: '',
            // 派单总方量
            total_quantity: '',
            wait_car_list: [],
            WaitCarActive: null,
            // 机组列表
            lineList: [],
            activeLine: {},

            // 当前操作的 数据索引
            curRowIndex: 0,

            // 保存的表单数据
            form: {
                product_type: 1,
                plate_number: '',
                driver_name: '',
                driver_phone: '',
                line_code: '',
                line_name: '',
                bulk_density: null,
                tasks: [],
            },

        };
    },
    props: {
        extr: Object,
    },
    watch: {
        list() {
            this.changeVolume();
        },
    },
    created() {
        this.filterParams = this.extr.filterParams;
        this.wait_car_list = this.extr.wait_car_list;
        this.WaitCarActive = this.extr.WaitCarActive;
        this.getLineList();
    },
    methods: {
        // 获取生产机组列表
        getLineList() {
            this.$axios.$get('/interfaceApi/production/mixstationline/' + this.filterParams.station)
                .then(res => {
                    this.lineList = res;
                    if (res.length > 0) {
                        this.form.line_code = res[0].line_code;
                        this.form.line_name = res[0].line_name;
                        this.activeLine = res[0];
                    }
                });
        },
        // 生产机组变化
        lineChange(val) {
            const obj = this.lineList.find(item => item.line_code === val);
            this.form.line_name = obj.line_name;
            this.activeLine = obj;
        },

        // 保存数据
        async saveData() {
            if (!this.selectCar.plate_small_number) {
                this.$message.warning('请选择车号');
                return;
            }
            if (this.list.length === 0) {
                this.$message.warning('请添加任务单');
                return;
            }
            let isSame = true;
            const tempBh = this.list[0].strength_grade_name + ' ' + this.list[0].special_require_name;
            for (const item of this.list) {
                if (this.form.product_type === 1 && !item.concrete_volume || this.form.product_type === 2 && !item.mortar_volume) {
                    this.$message.warning('请填写方量');
                    return;
                }
                const bh = item.strength_grade_name + ' ' + item.special_require_name;
                if (isSame && bh !== tempBh) {
                    isSame = false;
                }
            }
            // 如果选择是混凝土 需要判断产品标号是否一致，不一致 给出提示
            if (this.form.product_type === 1 && !isSame) {
                try {
                    await this.$confirm('任务单产品标号不一致，请确认', '提示', {
                        cancelButtonText: '重新选择',
                        confirmButtonText: '确认',
                        type: 'warning',
                    });
                } catch (e) {
                    return e;
                }
            }
            this.loading = true;
            const tasks = [];
            const xdrw = this.$takeTokenParameters('Name');
            this.list.forEach(item => {
                const obj = {
                    mixstation_code: item.station,
                    mixstation_name: item.station_name,
                    line_code: this.form.line_code,
                    line_name: this.form.line_name,
                    project_code: item.project_code,
                    project_name: item.project_name,
                    task_number: item.task_number,
                    scbt: this.activeLine.scbt,
                    xdrw: xdrw,
                    shch: this.selectCar.plate_small_number,
                    plate_number: this.selectCar.plate_number,
                    vehicle_type_code: this.selectCar.vehicle_type_code,
                    vehicle_type: this.selectCar.vehicle_type,
                    sjxm: this.selectCar.driver_name,
                    sjsjh: this.selectCar.driver_phone,
                    concrete_volume: Number(item.concrete_volume),
                    mortar_volume: Number(item.mortar_volume),
                    volume: Number(item.concrete_volume) + Number(item.mortar_volume),
                    sign_volume: 0,
                    back_volume: 0,
                    overage_volume: 0,
                    virtual_volume: 0,
                    pull_water: 0,
                    back_pcb_number: '',
                    panfs: 0,
                    c1: 0,
                    c2: 0,
                    haul_distance: item.haul_distance || 0,
                    strength_grade: item.strength_grade,
                    strength_grade_name: item.strength_grade_name,
                    special_require: item.special_require,
                    special_require_name: item.special_require_name,
                    change_reason: item.strength_grade_name,
                    remarks: item.remarks,
                };
                tasks.push(obj);
            });
            this.form.tasks = tasks;
            const client_id = sessionStorage.getItem('clientID');
            this.$axios.post('/interfaceApi/production/productpcb/save_multiple/' + client_id, this.form, { defEx: true })
                .then(() => {
                    this.$message.success('确认成功');
                    this.extr.success();
                    this.$parent.hide();
                }).catch(e => {
                    if (e.ErrorCode) {
                        this.$message.error(e.ErrorCode.Message);
                    }
                }).finally(() => {
                    this.loading = false;
                });
        },

        // 计算派出总方量
        changeVolume() {
            let total = 0;
            this.list.forEach(item => {
                if (this.form.product_type === 1) {
                    total += Number(item.concrete_volume) || 0;
                } else {
                    total += Number(item.mortar_volume) || 0;
                }
            });
            this.total_quantity = total;
        },
        // 上移
        upMove(index) {
            const prevIndex = index - 1;
            const temp = this.list[index];
            const prev = this.list[prevIndex];
            this.list.splice(prevIndex, 2, temp, prev);
        },
        // 下移
        downMove(index) {
            const temp = this.list[index];
            const next = this.list[index + 1];
            this.list.splice(index, 2, next, temp);
        },
        // 移除行
        removeRow(index) {
            this.list.splice(index, 1);
        },
        // 选择订单
        selectOrder(checkType, index) {
            this.curRowIndex = index;
            const filterParams = Object.assign(this.filterParams);
            filterParams.product_type = this.form.product_type;
            filterParams.selected_task = this.list.map(item => item.task_number);
            this.$toast({
                title: true,
                text: '选择任务单',
                type: 'eject',
                width: '15rem',
                height: '80%',
                t_url: 'productionManagement/productionDelivery/multipleOrderOneCar/selectOrderPop',
                extr: {
                    checkType: checkType,
                    filterParams: filterParams,
                    selectCallBack: this.selectOrderCallBackFn,
                },
            });
        },
        // 选择发货单回调
        selectOrderCallBackFn(data, checkType) {
            data.forEach(item => {
                item.concrete_volume = null;
                item.mortar_volume = null;
            });
            if (checkType === 'checkbox') {
                this.list.push(...data);
            } else {
                this.list.splice(this.curRowIndex, 1, data[0]);
            }
        },

        // 选择生产机组
        selectCarNo() {
            this.$toast({
                title: true,
                text: '选择车辆',
                type: 'eject',
                width: '14rem',
                height: '8rem',
                t_url: 'productionManagement/productionDelivery/pullWaterForm/selectVehicle',
                extr: {
                    wait_car_list: this.wait_car_list,
                    WaitCarActive: this.WaitCarActive,
                    sureCallback: this.selectWaitCarCallback,
                },
            });
        },
        // 选择生产机组回调
        selectWaitCarCallback(data) {
            this.selectCar = data;
            this.WaitCarActive = data.plate_small_number;

            this.form.plate_number = data.plate_number;
            this.form.driver_phone = data.driver_phone;
            this.form.driver_name = data.driver_name;
            this.form.bulk_density = data.bulk_density + 2;
        },
        cancel() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
    .multipleOrderOneCar
        width 100%
        height 100%
        padding 0.2rem 0.2rem 0;
        overflow-y auto
        position relative

        .top-wrapper
            width 100%;
            flex-shrink 0
            padding 0.08rem

            .title
                font-size 0.24rem
                color #333333
                font-weight 500

        .car-info
            background: #F5F6F7;
            padding 0 0 0.1rem
            display flex
            flex-wrap wrap
            justify-content space-between
            font-size 0.18rem
            color #333;

            .info-item
                width 25%
                padding 0.1rem 0.1rem
                display flex
                align-items center

                .name
                    width 1rem;
                    text-align right
                    white-space nowrap

                .el-select, .el-input
                    width: 2.6rem;
                    background: #FFFFFF;
                    border-radius: 0.04rem;
                    font-size 0.16rem

        .task-order-list
            padding-top 0.06rem

            .top-wrapper
                .title
                    margin-right 0.2rem

            .table-box
                .table-head
                    padding-right: 0.1rem;
                    color: #5C628C;
                    font-size 0.16rem

                    table
                        word-break: break-all;
                        table-layout: fixed;
                        width 100%
                        border: 1px solid #C7D2F2;
                        border-collapse: collapse;

                        th
                            background: #E7EDF2;
                            padding 0.08rem 0
                            border: 1px solid #C7D2F2;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;

                .table-body
                    width: 100%;
                    height: 2.5rem;
                    overflow-y: scroll;

                    table
                        word-break: break-all;
                        table-layout: fixed;
                        width 100%
                        border: 1px solid #E0E7FB;
                        border-collapse: collapse;

                        tr
                            display flex
                            td
                                border: 1px solid #E0E7FB;
                                padding 0.05rem 0.1rem 0.02rem
                                text-align center
                                font-size 0.16rem
                                /*line-height 0.33rem*/
                                .cell
                                    height 0.32rem;
                                    line-height 0.32rem
                                    text-align center
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    .delbtn
                                        height 0.25rem;
                                        padding 0 0.1rem;
                                >>> .el-input
                                    .el-input__inner
                                        background: #F5F5F5;
                                        height: 0.3rem;
                                        border-radius: 0.02rem;
                                        border: 1px solid #D0D0D0;

                                .sort-btn
                                    margin 0 0.1rem
                                    display inline-block
                                    width: 0.24rem;
                                    height: 0.24rem;
                                    line-height 0.24rem;
                                    text-align center
                                    background: #2978FF;
                                    border-radius: 0.04rem;
                                    font-size 0.16rem
                                    color #fff
                                    cursor pointer

                                    &:active
                                        transform scale(1.05)

                                    &.disable
                                        opacity: 0.3;
                                        cursor: not-allowed
                                        pointer-events: none

                        tr:nth-child(2n)
                            background: #FAFBFF;

        .add-btn
            margin-top 0.15rem
            display flex
            justify-content center
            align-items center
            height: 0.48rem;
            background: #FCFCFC;
            border: 1px dashed #DFDFDF;
            font-size 0.16rem
            color #979EA7
            cursor pointer

            &:hover
                color #666

            .iconfont
                font-size 0.16rem;
                margin-right 0.05rem

        .tips
            margin-top 0.15rem
            padding-left 0.15rem
            display flex
            align-items center
            height: 0.48rem;
            background: #FFFBE6;
            border: 1px solid #FFE58F;
            font-size 0.16rem
            color #FDA903

            .iconfont
                margin-right: 0.05rem;

        .btns-wrapper
            width 100%
            padding 0.25rem 0
            position absolute
            left 0
            bottom 0
            display flex
            justify-content center
            background #fff

            .el-button
                margin 0 0.4rem
                display flex
                justify-content center
                align-items center
                width: 2.4rem;
                height: 0.5rem;
                border-radius: 0.02rem;
                font-size 0.22rem
                color #fff;

                &.cancel-btn
                    color #2978FF
                    border: 0.02rem solid #2978FF;
                    color #2978FF

                &.sure-btn
                    background: #2978FF;
                    border-radius: 0.02rem;
</style>
